// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nav-bar {
  background-color: #465472;
  display: flex;
  flex-direction: row;
  justify-content: left;
  font-size: calc(10px + 2vmin);
  border: 10px solid #778296;
}

h2 {
  color: white;
  margin: 10px;
}

a:link {
  color: white;
  margin: 10px;
}

a:visited {
  color: #61dafb;
  margin: 10px;
}

a:hover {
  color: coral;
  margin: 10px;
}

a:active {
  color: red;
  margin: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/Components/NavBar.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,aAAa;EACb,mBAAmB;EACnB,qBAAqB;EACrB,6BAA6B;EAC7B,0BAA0B;AAC5B;;AAEA;EACE,YAAY;EACZ,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,YAAY;AACd;;AAEA;EACE,cAAc;EACd,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,YAAY;AACd;;AAEA;EACE,UAAU;EACV,YAAY;AACd","sourcesContent":[".nav-bar {\n  background-color: #465472;\n  display: flex;\n  flex-direction: row;\n  justify-content: left;\n  font-size: calc(10px + 2vmin);\n  border: 10px solid #778296;\n}\n\nh2 {\n  color: white;\n  margin: 10px;\n}\n\na:link {\n  color: white;\n  margin: 10px;\n}\n\na:visited {\n  color: #61dafb;\n  margin: 10px;\n}\n\na:hover {\n  color: coral;\n  margin: 10px;\n}\n\na:active {\n  color: red;\n  margin: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
