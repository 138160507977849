// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-title {
    color: rgb(11, 11, 11);
  }
  
  .card-deck {
    display: flex;
    margin: auto;
  }
  
  .card {
    display: flex;
    justify-content: left;
    max-width: 320px;
    min-width: 280px;
    border: 1px solid black;
    margin: 15px;
    box-shadow: 5px 10px rgb(175, 153, 114)
    
  }
  
  li {
    margin: 0;
    border: 0;
    padding: 0;
    height: auto;
  }`, "",{"version":3,"sources":["webpack://./src/Components/EntryCards.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;EACxB;;EAEA;IACE,aAAa;IACb,YAAY;EACd;;EAEA;IACE,aAAa;IACb,qBAAqB;IACrB,gBAAgB;IAChB,gBAAgB;IAChB,uBAAuB;IACvB,YAAY;IACZ;;EAEF;;EAEA;IACE,SAAS;IACT,SAAS;IACT,UAAU;IACV,YAAY;EACd","sourcesContent":[".card-title {\n    color: rgb(11, 11, 11);\n  }\n  \n  .card-deck {\n    display: flex;\n    margin: auto;\n  }\n  \n  .card {\n    display: flex;\n    justify-content: left;\n    max-width: 320px;\n    min-width: 280px;\n    border: 1px solid black;\n    margin: 15px;\n    box-shadow: 5px 10px rgb(175, 153, 114)\n    \n  }\n  \n  li {\n    margin: 0;\n    border: 0;\n    padding: 0;\n    height: auto;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
