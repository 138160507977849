import React from "react";
import "./Footer.css";

function Footer() {
  return (
    <footer>
      <small>
        &copy; Warrior Goat Web Services - 2023: All Rights Reserved
      </small>
    </footer>
  );
}

export default Footer;
