// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `footer {
  color: rgb(166, 163, 163);
  background-color: rgb(38, 38, 38);
  font-family: helvetica, arial, sans;
  text-align: center;
  text-shadow: 2px 2px 2px rgba(99, 99, 241, 0.4);
  padding: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Footer.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,iCAAiC;EACjC,mCAAmC;EACnC,kBAAkB;EAClB,+CAA+C;EAC/C,aAAa;AACf","sourcesContent":["footer {\n  color: rgb(166, 163, 163);\n  background-color: rgb(38, 38, 38);\n  font-family: helvetica, arial, sans;\n  text-align: center;\n  text-shadow: 2px 2px 2px rgba(99, 99, 241, 0.4);\n  padding: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
