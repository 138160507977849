// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../public/pexels-jeshootscom-834892.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
    margin: 0;
    font-family: Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-image:  url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
   
  }
  div{
    margin: 10px;
    padding: 10px;
    color: rgb(248, 245, 222);
    text-align: center;
    text-shadow: 1px 1px 1px rgb(7, 4, 1);;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}
.layout{
  background-color: rgba(97, 78, 41, 0.6);
}
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;IACI,SAAS;IACT,yCAAyC;IACzC,mCAAmC;IACnC,kCAAkC;IAClC,0DAAiE;IACjE,4BAA4B;IAC5B,kCAAkC;IAClC,sBAAsB;;EAExB;EACA;IACE,YAAY;IACZ,aAAa;IACb,yBAAyB;IACzB,kBAAkB;IAClB,qCAAqC;IACrC,aAAa;IACb,sBAAsB;IACtB,eAAe;IACf,mBAAmB;IACnB,uBAAuB;AAC3B;AACA;EACE,uCAAuC;AACzC;EACE;IACE;eACW;EACb","sourcesContent":["body {\n    margin: 0;\n    font-family: Helvetica, Arial, sans-serif;\n    -webkit-font-smoothing: antialiased;\n    -moz-osx-font-smoothing: grayscale;\n    background-image:  url(\"../public/pexels-jeshootscom-834892.jpg\");\n    background-repeat: no-repeat;\n    background-position: center center;\n    background-size: cover;\n   \n  }\n  div{\n    margin: 10px;\n    padding: 10px;\n    color: rgb(248, 245, 222);\n    text-align: center;\n    text-shadow: 1px 1px 1px rgb(7, 4, 1);;\n    display: flex;\n    flex-direction: column;\n    flex-wrap: wrap;\n    align-items: center;\n    justify-content: center;\n}\n.layout{\n  background-color: rgba(97, 78, 41, 0.6);\n}\n  code {\n    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n      monospace;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
