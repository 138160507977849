// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
    background-color: #465472;
    color: white;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

  }
  
  p {
    color: white;
    font-family: helvetica, arial, sans;
    text-align: center;
    text-shadow: 2px 2px 2px rgba(98, 98, 162, 0.4);
    padding: 10px;
  }
  .button {
    display: flex;
    align-items: center;
    justify-self: center;
    justify-content: center;
    width: 120px;
    margin: auto;
  }
  
  .btn {
    background-color: blueviolet;
    color: blue;
    width: 80px;
    border: 1px solid black;
    margin: 15px;
  }

  .nav-bar {
    background-color: #465472;
    display: flex;
    flex-direction: row;
    justify-content: left;
    font-size: calc(10px + 2vmin);
    border: 10px solid #778296;
  }
  
  h2 {
    color: white;
    margin: 10px;
  }
  
  a:link {
    color: white;
    margin: 10px;
  }
  
  a:visited {
    color: #61dafb;
    margin: 10px;
  }
  
  a:hover {
    color: coral;
    margin: 10px;
  }
  
  a:active {
    color: red;
    margin: 10px;
  }`, "",{"version":3,"sources":["webpack://./src/app.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,eAAe;IACf,mBAAmB;IACnB,uBAAuB;;EAEzB;;EAEA;IACE,YAAY;IACZ,mCAAmC;IACnC,kBAAkB;IAClB,+CAA+C;IAC/C,aAAa;EACf;EACA;IACE,aAAa;IACb,mBAAmB;IACnB,oBAAoB;IACpB,uBAAuB;IACvB,YAAY;IACZ,YAAY;EACd;;EAEA;IACE,4BAA4B;IAC5B,WAAW;IACX,WAAW;IACX,uBAAuB;IACvB,YAAY;EACd;;EAEA;IACE,yBAAyB;IACzB,aAAa;IACb,mBAAmB;IACnB,qBAAqB;IACrB,6BAA6B;IAC7B,0BAA0B;EAC5B;;EAEA;IACE,YAAY;IACZ,YAAY;EACd;;EAEA;IACE,YAAY;IACZ,YAAY;EACd;;EAEA;IACE,cAAc;IACd,YAAY;EACd;;EAEA;IACE,YAAY;IACZ,YAAY;EACd;;EAEA;IACE,UAAU;IACV,YAAY;EACd","sourcesContent":["body {\n    background-color: #465472;\n    color: white;\n    display: flex;\n    flex-direction: column;\n    flex-wrap: wrap;\n    align-items: center;\n    justify-content: center;\n\n  }\n  \n  p {\n    color: white;\n    font-family: helvetica, arial, sans;\n    text-align: center;\n    text-shadow: 2px 2px 2px rgba(98, 98, 162, 0.4);\n    padding: 10px;\n  }\n  .button {\n    display: flex;\n    align-items: center;\n    justify-self: center;\n    justify-content: center;\n    width: 120px;\n    margin: auto;\n  }\n  \n  .btn {\n    background-color: blueviolet;\n    color: blue;\n    width: 80px;\n    border: 1px solid black;\n    margin: 15px;\n  }\n\n  .nav-bar {\n    background-color: #465472;\n    display: flex;\n    flex-direction: row;\n    justify-content: left;\n    font-size: calc(10px + 2vmin);\n    border: 10px solid #778296;\n  }\n  \n  h2 {\n    color: white;\n    margin: 10px;\n  }\n  \n  a:link {\n    color: white;\n    margin: 10px;\n  }\n  \n  a:visited {\n    color: #61dafb;\n    margin: 10px;\n  }\n  \n  a:hover {\n    color: coral;\n    margin: 10px;\n  }\n  \n  a:active {\n    color: red;\n    margin: 10px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
