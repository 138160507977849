import React from "react";


//blogs page component
const BlogsPage = () => {


  return (
    <div >
      <h1>Welcome to our Blogs Page </h1>
      <h2>Here you can find a collection of articles to help you with your project.</h2>
      
    </div>
  );
};

export default BlogsPage;
