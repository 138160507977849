import React from "react"



const ALLink = ""

const AKLink = ""

const ARLink = ""

const AZLink = ""

const CALink = ""

const COLink = ""

const CTLink = ""

const DCLink = ""

const DELink = ""

const FLLink = ""

const GALink = ""

const GULink = ""

const HILink = ""

const IALink = ""

const IDLink = ""

const ILLink = ""

const INLink = ""

const KSLink = ""

const KYLink = ""

const LALink = ""

const MALink = ""

const MDLink = ""

const MELink = ""

const MILink = "https://aca-prod.accela.com/LARA/GeneralProperty/LicenseeDetail.aspx?LicenseeNumber="

const MNLink = ""

const MOLink = ""

const MSLink = ""

const MTLink = ""

const NCLink = ""

const NELink = ""

const NHLink = ""

const NJLink = "" 

const NMLink = "" 

const NVLink = "" 

const NYLink = "" 

const NDLink = ""

const OHLink = "" 

const OKLink = "" 

const ORLink  = "" 

const PALink = ""

const PRLink = "" 

const RILink = "" 

const SCLink = "" 

const SDLink = "" 

const TNLink = "" 

const TXLink = "" 

const UTLink = "" 

const VILink = "" 

const VTLink = "" 

const VALink = "" 

const WALink = "" 

const WILink = "" 

const WVLink = ""

const WYLink = ""

export {
    ALLink, AKLink, ARLink, AZLink, CALink, COLink, CTLink, DCLink, DELink, FLLink, GALink, GULink, HILink, IALink, IDLink, ILLink, INLink, KSLink, KYLink, LALink, MALink, MDLink, MELink, MILink, MNLink, MOLink, MSLink, MTLink, NCLink, NELink, NHLink, NJLink, NMLink, NVLink, NYLink, NDLink, OHLink, OKLink, ORLink, PALink, PRLink, RILink, SCLink, SDLink, TNLink, TXLink, UTLink, VILink, VTLink, VALink, WALink, WILink, WVLink, WYLink
}